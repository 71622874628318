
const digest = 'f1a7a03b9f28f6913fb1eeb499d8e98e197ddc6dbb20a4ea2fdd44ff6217ece0';
const css = `._spinner_3vod1_1 {
  width: 2rem;
  height: 2rem;
  color: var(--color-brand);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"spinner":"_spinner_3vod1_1"};
export { css, digest };
  
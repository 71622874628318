
const digest = '26cbdefb6223a594f1a18549beee9c7de3e0bc4acc7c21ba3452efa5cc58c0f5';
const css = `._fieldGroup_1b8g6_1 {
  align-items: center;
  display: flex;
  width: 100%;
}

._fieldGroupOption_1b8g6_7 {
  background-color: var(--color-white);
  color: var(--color-neutral-10);
  line-height: 1;
  width: 100%;
}

._fieldGroupBlock_1b8g6_14 {
  border-radius: 0;
}

._fieldGroupOption_1b8g6_7:first-child ._fieldGroupBlock_1b8g6_14 {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

._fieldGroupOption_1b8g6_7:not(:first-child) ._fieldGroupBlock_1b8g6_14 {
  border-radius: 0;
  /* correct for overlapping/doubling up borders */
  margin-left: -1px;
  width: calc(100% + 1px);
}

._fieldGroupOption_1b8g6_7:not(:first-child):last-child ._fieldGroupBlock_1b8g6_14 {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"fieldGroup":"_fieldGroup_1b8g6_1","fieldGroupOption":"_fieldGroupOption_1b8g6_7","fieldGroupBlock":"_fieldGroupBlock_1b8g6_14"};
export { css, digest };
  